import React from "react"
import { localize } from "../../Atoms/Language"

const ActiveFoamPower = () => (
  <section className="mt16 mb8">
    <h3 className="header4">{localize("73")}</h3>
    <p className="body gray600">{localize("74")}</p>
  </section>
)

export default ActiveFoamPower
