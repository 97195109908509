import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "carwash1" }) {
      childImageSharp {
        fixed(width: 540, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledImageWithText = styled(ImageWithText)`
  margin-bottom: 0 !important;
`

const ChemicalsForWashes = () => {
  const data = useStaticQuery(imageQuery)
  return (
    <>
      <StyledImageWithText className="mt8 mb8" fixed={data.file.childImageSharp.fixed} imageAltText={localize("6")}>
        <h1 className="header2">{localize("42")}</h1>
        <p className="body">{localize("43")}</p>
        <p className="body">{localize("44")}</p>
      </StyledImageWithText>
      <p className="body gray600">{localize("45")}</p>
      <p className="body gray600">{localize("46")}</p>
    </>
  )
}
export default ChemicalsForWashes
