import React from "react"
import { localize } from "../../Atoms/Language"

const ProfessionalChemicals = () => (
  <section className="mb16">
    <h3 className="header4">{localize("75")}</h3>
    <p className="body gray600">{localize("76")}</p>
  </section>
)

export default ProfessionalChemicals
