import React from "react"
import styled from "styled-components"
import Grid from "../../Atoms/Grid"
import ChemicalElement from "../../Molecules/ChemicalsElement/ChemicalElement"
import CleanBronze from "../../../assets/images/chemia/CleanBronze.png"
import CleanGold from "../../../assets/images/chemia/CleanGold.png"
import CleanSilver from "../../../assets/images/chemia/CleanSilver.png"
import FoamBronze from "../../../assets/images/chemia/FoamBronze.png"
import FoamGold from "../../../assets/images/chemia/FoamGold.png"
import FoamSilver from "../../../assets/images/chemia/FoamSilver.png"
import PulverBronze from "../../../assets/images/chemia/PulverBronze.png"
import PulverEcologic from "../../../assets/images/chemia/PulverEcologic.png"
import PulverGold from "../../../assets/images/chemia/PulverGold.png"
import PulverSilver from "../../../assets/images/chemia/PulverSilver.png"
import WaxBronze from "../../../assets/images/chemia/WaxBronze.png"
import WaxSilver from "../../../assets/images/chemia/WaxSilver.png"
import WaxGold from "../../../assets/images/chemia/WaxGold.png"
import { localize } from "../../Atoms/Language"

const StyledGrid = styled(Grid)`
  justify-content: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: 1124px) {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 855px) {
    flex-direction: column;
  }
`

const Chemicals = () => (
  <section className="mt16">
    <h2 className="header3">{localize("47")}</h2>
    <StyledGrid className="mb8">
      <ChemicalElement
        image={PulverGold}
        name="MY PULVER GOLD"
        subtitle={localize("48")}
        description={localize("49")}
      />
      <ChemicalElement
        image={PulverSilver}
        name="MY PULVER SILVER"
        subtitle={localize("48")}
        description={localize("50")}
      />
      <ChemicalElement
        image={PulverBronze}
        name="MY PULVER BRONZE"
        subtitle={localize("51")}
        description={localize("52")}
      />
      <ChemicalElement
        image={PulverEcologic}
        name="MY PULVER ECOLOGIC"
        subtitle={localize("48")}
        description={localize("53")}
      />
    </StyledGrid>
    <h2 className="header3">{localize("54")}</h2>
    <StyledGrid className="mb8">
      <ChemicalElement
        image={FoamGold}
        name="MY FOAM GOLD"
        subtitle={localize("55")}
        description={localize("56")}
      />
      <ChemicalElement
        image={FoamSilver}
        name="MY FOAM SILVER"
        subtitle={localize("55")}
        description={localize("57")}
      />
      <ChemicalElement
        image={FoamBronze}
        name="MY FOAM BRONZE"
        subtitle={localize("58")}
        description={localize("59")}
      />
    </StyledGrid>
    <h2 className="header3">{localize("60")}</h2>
    <StyledGrid className="mb8">
      <ChemicalElement
        image={WaxGold}
        name="MY WAX GOLD"
        subtitle={localize("61")}
        description={localize("62")}
      />
      <ChemicalElement
        image={WaxSilver}
        name="MY WAX SILVER"
        subtitle={localize("63")}
        description={localize("64")}
      />
      <ChemicalElement
        image={WaxBronze}
        name="MY WAX BRONZE"
        subtitle={localize("65")}
        description={localize("66")}
      />
    </StyledGrid>
    <h2 className="header3">{localize("67")}</h2>
    <StyledGrid className="mb8">
      <ChemicalElement
        image={CleanGold}
        name="MY CLEAN GOLD"
        subtitle={localize("68")}
        description={localize("69")}
      />
      <ChemicalElement
        image={CleanSilver}
        name="MY CLEAN SILVER"
        subtitle={localize("68")}
        description={localize("70")}
      />
      <ChemicalElement
        image={CleanBronze}
        name="MY CLEAN BRONZE"
        subtitle={localize("71")}
        description={localize("72")}
      />
    </StyledGrid>
  </section>
)

export default Chemicals
