import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"

const StyledWrapper = styled.li`
  flex: 1;
  min-width: 350px;
  margin-right: 30px;

  &:nth-child(n + 3) {
    margin-right: 0;
  }

  span {
    font-family: Inter;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 22px;
  }

  h5 {
    text-transform: none;
    margin-top: 8px;
  }

  :nth-child(4) {
    flex: 3;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 80px;

    .image {
      margin-right: 30px;
      min-width: 350px;
    }
  }

  @media screen and (max-width: 1124px) {
    flex: 1 calc(50% - 30px);
    max-width: 365px;
    min-width: 0;
    margin-bottom: 40px;

    &:nth-child(even) {
      margin-right: 0;
    }

    &:nth-child(odd) {
      margin-right: 30px;
    }

    :nth-child(4) {
      flex: 1;
      flex-direction: column;
      margin-top: 0;
      .image {
        margin-right: 0;
        min-width: 0;
      }
    }
  }

  @media screen and (max-width: 855px) {
    flex: 1 100%;
    margin-left: auto;
    
    &:nth-child(even) {
      margin-right: auto;
    }

    &:nth-child(odd) {
      margin-right: auto;
    }
  }
`

const StyledImage = styled.div`
  width: 100%;
  height: 280px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid ${GlobalColors.gray300};
`

const ChemicalElement = ({ image, name, subtitle, description }) => (
  <StyledWrapper>
    <StyledImage className="image" image={image} />
    <div>
      <h4 className="header4 no-margin">{name}</h4>
      <h5 className="label gray600">{subtitle}</h5>
      <p className="body-small gray600">{description}</p>
    </div>
  </StyledWrapper>
)

export default ChemicalElement
