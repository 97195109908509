import React from "react"
import Chemicals from "../components/Organisms/StaticSections/chemicals"
import CTABanner from "../components/Organisms/CTABanner/CTABanner"
import ChemicalsForWashes from "../components/Organisms/StaticSections/ChemicalsForWashes"
import ActiveFoamPower from "../components/Organisms/StaticSections/ActiveFoamPower"
import ProfessionalChemicals from "../components/Organisms/StaticSections/ProfesionalChemicals"
import { localize } from "../components/Atoms/Language"
import SEO from "../components/seo"
import { WithIntl } from "../components/Atoms/withTranslate"

const ChemicalsPage = ({ language }) => (
  <div className="container">
    <SEO
      lang={language}
      title={localize("77")}
      description={localize("78")}
    />
    <ChemicalsForWashes/>
    <Chemicals/>
    <ActiveFoamPower/>
    <ProfessionalChemicals/>
    <CTABanner language={language}/>
  </div>
)

export default WithIntl()(ChemicalsPage)
